<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form v-model="isValid">
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="editedItem.name.value"
                                    class="purple-input"
                                    :label="$t('admin.users.firstname')"
                                    :error-messages="editedItem.name.error"
                                    :rules="[rules.required]"
                                    @keyup="editedItem.name.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="editedItem.lastname.value"
                                    class="purple-input"
                                    :label="$t('admin.users.lastname')"
                                    :error-messages="editedItem.lastname.error"
                                    @keyup="editedItem.lastname.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="editedItem.username.value"
                                    class="purple-input"
                                    :label="$t('admin.users.username')"
                                    :error-messages="editedItem.username.error"
                                    :rules="[rules.required]"
                                    @keyup="editedItem.username.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="editedItem.email.value"
                                    :label="$t('admin.users.email')"
                                    class="purple-input"
                                    :error-messages="editedItem.email.error"
                                    :rules="[ rules.validEmail]"
                                    @keyup="editedItem.email.error = ''"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->

                                <v-select
                                    v-model="editedItem.roles.value"
                                    :error-messages="editedItem.roles.error"
                                    :items="getRoles"
                                    attach
                                    chips
                                    :label="$t('roles')"
                                    multiple
                                    item-text="attributes.display_name"
                                    item-value="id"
                                    @change="editedItem.roles.error = ''"
                                />
                            </v-col>

                            <!--                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <groups-select
                                    :items-selected="groupsSelected"
                                    @update:itemsSelected="changeGroupsSelected"
                                />
                            </v-col>-->

                            <v-col
                                cols="12"
                                sm="12"
                            >
                                <!--                              :disabled="isOnlyView"-->
                                <v-autocomplete
                                    v-model="editedItem.company.value"
                                    :items="companies"
                                    :label="$t('companies.selectCompany')"
                                    item-text="attributes.name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    :error-messages="editedItem.company.error"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                offset-sm="4"
                            >
                                <v-switch
                                    v-model="editedItem.enable"
                                    class="mx-2"
                                    :label="editedItem.enable ? $t('admin.users.enabledText') : $t('admin.users.disabledText')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'
// import GroupsSelect from '@/components/admin/groups/groupsSelect'

export default {
    // components: { GroupsSelect },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        }
    },
    data: function () {
        return {
            urlMain: '/users',
            itemsRoles: [],
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            companies: []
            /* groupsSelected: [] */
        }
    },

    computed: {
        formTitle() {
            return this.item
                ? this.$t('admin.users.edit-user')
                : this.$t('admin.users.new-user')
        },
        ...mapGetters('permissions', ['getRoles'])
    },
    watch: {

        /* search: {
        handler(newValue) {
            this.fetchUsers();
            /!* if (!this.debouncedFetchItems) {
                this.debouncedFetchItems = _.debounce(this.fetchUsers, 1000)
            }
            this.debouncedFetchItems() *!/
        }
    }, */

        /* dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        } */
    },

    // Fetches posts when the component is created.
    created() {
        this.fetchCompanies();
        // if (this.item?.id) { this.fetchGroupsFromUser(); }

        // this.fetchUsers();
        /* axios
    .get("/roles")
    .then((response) => {
        this.setRole(response.data);
    })
    .catch((error) => {
        // window.console.log(error);
    }); */
    },

    mounted() {

        /* if (!this.role || this.role != 2) {
    // 2 Admin  4 Agent
    this.$router.push({
        path: "/"
    });
} */
    },

    methods: {
        getEditedItem() {
            const item = this.item
            /* console.log('******* item **********')
            console.log(item) */

            var empty = !item

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.attributes.name,
                    error: ''
                },
                lastname: {
                    value: empty ? '' : item.attributes.lastname,
                    error: ''
                },
                username: {
                    value: empty ? '' : item.attributes.username,
                    error: ''
                },
                email: {
                    value: empty ? '' : item.attributes.email,
                    error: ''
                },
                enable: empty ? false : item.attributes.enable === 1,

                roles: {
                    value: empty ? '' : item?.relationships?.roles?.data?.map(r => r.id),
                    error: ''
                },
                company: {
                    value: empty ? '' : item.attributes.company_id + '',
                    error: ''
                }
            }
        },

        /* setRole($data) {
    this.itemsRoles = $data;
},

deleteItem() {

},
*/

        close() {
            this.$emit('update:dialog', false)

        /* this.$nextTick(() => {
            this.editedIndex = -1
        }) */
        },

        async save() {
            var dataSubmit = {
                data: {
                    type: 'users',
                    attributes: {
                        username: this.editedItem.username.value,
                        name: this.editedItem.name.value,
                        lastname: this.editedItem.lastname.value,
                        email: this.editedItem.email.value,
                        enable: !!this.editedItem.enable,
                        company_id: this.editedItem.company.value
                    }
                }
            }

            let urlAppend = ''
            let method = 'post'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                dataSubmit.data.id = '' + this.editedItem.id

                method = 'patch'
            }

            // for send the roles in JSON API standarization
            const roles = this.editedItem.roles.value;
            dataSubmit.data.relationships = {
                roles: {
                    data: roles && roles.length ? roles.map(r => { return { id: r } }) : []
                }
            }

            // for send the groups in JSON API standarization
            /* const groups = this.groupsSelected;
            if (groups && groups.length) {
                dataSubmit.data.relationships.groups = {
                    data: groups.map(r => { return { id: r } })
                }
            } */

            // console.log(dataSubmit);

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                })

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                if (error.response.status === 422) {
                    /* console.log('********** errors ********')

                    console.log(error.response.data.errors) */

                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            const attributes = ['lastname', 'email', 'name', 'username', 'company'];
                            for (const attribute of attributes) {
                                if (e.detail.includes(`data.attributes.${attribute}`)) {
                                    this.editedItem[attribute].error = e.detail
                                }
                            }
                        })
                    }
                }

                /* var errors = error.response.data.errors

          if (errors.name) {
              this.editedItem.name.error = errors.name
          }
          if (errors.lastname) this.editedItem.lastname.error = errors.lastname
          if (errors.email) this.editedItem.email.error = errors.email
          if (errors.enable) this.editedItem.enable.error = errors.enable */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        }, /* changeGroupsSelected(groupsSelected) {
            this.groupsSelected = groupsSelected;
        } */

        /* async fetchGroupsFromUser() {
            try {
                const groups = await axios
                    .get('/groupsByUserId/' + this.item.id)

                const data = groups.data;

                this.groupsSelected = data.map(e => e + "");
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        } */

        async fetchCompanies() {
            try {
                const result = await axios
                    .get("companies");

                this.companies = result.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>
